import { Discord } from '@static/images/discord';
import github from '@static/images/githab.svg';
import logo from '@static/images/logo.svg';
import { Twitter } from '@static/images/twitter';

import './index.scss';
import { DISCORD } from '@app/constants/socialLinks';

export const Navbar: React.FC = () => {
    return <nav className="navbar">
        <div className="navbar__wrapper">
            <a
                className="navbar__logo"
                href="/"
                aria-label="navbar logo"
            >
                <img
                    className="navbar__logo__image"
                    alt="suirdrops logo"
                    src={logo}
                />
            </a>
            <div className="navbar__links">
                <a
                    className="navbar__links__item"
                    href={DISCORD}
                    rel="noopener"
                    aria-label="navbar discord"
                    target="_blank"
                >
                    <Discord />
                </a>
                {/*
                <a
                    className="navbar__links__item"
                    href="/"
                    target="_blank"
                    rel="noopener"
                    aria-label="navbar github"
                >
                    <img src={github} alt="github" />
                </a> */}
                <a
                    className="navbar__links__item"
                    href="https://twitter.com/tricorn_network"
                    target="_blank"
                    rel="noopener"
                    aria-label="navbar twitter"
                >
                    <Twitter />
                </a>
            </div>
            <a className="navbar__btn" href="https://bridge.tricorn.network/" target="_blank">
                Launch App
            </a>
        </div>
    </nav>
};
