import { Discord } from '@static/images/discord'
import github from '@static/images/githab.svg'
import logo from '@static/images/logo.svg'
import { Twitter } from '@static/images/twitter'
import appConfig from '@app/configs/appConfig.json'

import './index.scss'
import { Link } from 'react-router-dom'
import { DISCORD } from '@app/constants/socialLinks'

export class ImageItem {
    constructor(
        public src: string = '',
        public link: string = '',
        public alt: string = '',
    ) {}
}

export const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <div className="footer__top-section">
                    <div className="footer__main">
                        <div className="footer__main__about">
                            <div>
                                <img
                                    src={logo}
                                    alt="logo"
                                    className="footer__main__about__logo"
                                    loading="lazy"
                                />
                                <div className="footer__main__about__label">
                                    Cross-chain bridge for EVM and Non-EVM
                                    blockchains
                                </div>
                            </div>
                            <div className="footer__main__about__links">
                                <a
                                    href="https://twitter.com/tricorn_network"
                                    className="footer__main__about__links__item"
                                    aria-label="twitter"
                                    target="_blank"
                                >
                                    <Twitter />
                                </a>
                                <a
                                    href={DISCORD}
                                    className="footer__main__about__links__item"
                                    aria-label="discord"
                                    target="_blank"
                                >
                                    <Discord />
                                </a>
                                {/* <a
                                href="#"
                                className="footer__main__about__links__item"
                                aria-label="github"
                            >
                                <img
                                    src={github}
                                    alt="github"
                                />
                            </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer__links">
                        <div className="footer__links__list">
                            <a
                                className="footer__links__list__item"
                                aria-label="product"
                            >
                                Product
                            </a>
                            <a
                                className="footer__links__list__item"
                                aria-label="bridge app"
                                href="https://bridge.tricorn.network/"
                                target="_blank"
                            >
                                Bridge App
                            </a>
                            <a
                                className="footer__links__list__item"
                                aria-label="add yor blockchain"
                                href="mailto:contact@tricorn.network?subject=New network integration to Tricorn"
                            >
                                Add your blockchain
                            </a>
                            <a
                                className="footer__links__list__item"
                                aria-label="add token"
                                href="mailto:contact@tricorn.network?subject=New token integration to Tricorn"
                            >
                                Add Token
                            </a>
                            {/* <a className="footer__links__list__item" aria-label="github" href="#">
                            Github
                        </a> */}
                        </div>
                        <div className="footer__links__list">
                            <a
                                className="footer__links__list__item"
                                aria-label="company"
                            >
                                Company
                            </a>
                            <a
                                href="#about"
                                className="footer__links__list__item"
                                aria-label="about"
                            >
                                About
                            </a>
                            <a
                                href="#how-bridge-works"
                                className="footer__links__list__item"
                                aria-label="how bridge works"
                            >
                                How bridge works
                            </a>
                            <a
                                href="#ecosystem"
                                className="footer__links__list__item"
                                aria-label="ecosystem"
                            >
                                Ecosystem
                            </a>
                            <a
                                href="#partnerships"
                                className="footer__links__list__item"
                                aria-label="partnerships"
                            >
                                Partnerships
                            </a>
                            {/* <a href="#team" className="footer__links__list__item" aria-label="team">
                            Team
                        </a> */}
                            {/* <a href="#roadmap" className="footer__links__list__item" aria-label="roadmap">
                            Roadmap
                        </a> */}
                        </div>
                        <div className="footer__links__list">
                            <a
                                className="footer__links__list__item"
                                aria-label="help"
                            >
                                Help
                            </a>
                            <Link
                                onClick={() => {
                                    scrollTo(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.ZERO_NUMBER)
                                }}
                                to="/how-to-use"
                                className="footer__links__list__item"
                                aria-label="documentation"
                            >
                                How To Use
                            </Link>
                            <Link
                                onClick={() => {
                                    scrollTo(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.ZERO_NUMBER)
                                }}
                                to="/faq"
                                className="footer__links__list__item"
                                aria-label="FAQ"
                            >
                                FAQ
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className="footer__bottom-section">
                <a className="footer__bottom-section__link" aria-label="Terms" href="#">
                    Terms of Use
                </a>
                <a className="footer__bottom-section__link" aria-label="Privacy Policy" href="#">
                    Privacy Policy
                </a>
                <a className="footer__bottom-section__link" aria-label="Cookie Policy" href="#">
                    Cookie Policy
                </a>
            </div> */}
            </div>
        </footer>
    )
}
